html {
	width: 100vw;
	overflow-x: hidden;
}

* {
	font-family: "Lato", sans-serif;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

.MemeMarketBox,
#InfoCard {
	-webkit-font-smoothing: subpixel-antialiased;
	-webkit-font-smoothing: antialised;
	-webkit-filter: blur(0);
	backface-visibility: hidden;
	border-radius: 1px;
	box-shadow: 1px 5px 7px rgba(0, 0, 0, 0.1);
	border-radius: 1px;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.MemeMarketBox {
	box-shadow: 2px, 7px, 10px, (rgba0, 0, 0, 0.1);
}

.MemeMarketBox::after,
#InfoCard::after {
	content: "";
	border-radius: 1px;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 2px 7px 10px rgba(0, 0, 0, 0.3);
	opacity: 0;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	pointer-events: none;
	backface-visibility: hidden;
}

.MemeMarketBox:hover,
#InfoCard:hover {
	-webkit-transform: scale(1.00896, 1.00896) perspective(1px);
	transform: scale(1.00896, 1.00896) perspective(1px);
	transform: translateZ(0.5);
}

.MemeMarketBox:hover::after,
#InfoCard:hover::after {
	opacity: 1;
	pointer-events: none;
}
